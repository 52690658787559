(function ($) {
  $(function () {
    //Case studies slider
    //Only enabled on mobile
    if (document.querySelector("#case-studies-slider")) {
      const element = document.querySelector("#case-studies-slider");
      const breakpoint = 767; // define your breakpoint value

      const mediaQueryList = window.matchMedia(`(max-width: ${breakpoint}px)`);

      if (mediaQueryList.matches) {
        element.classList.add("case-studies-slider"); // add the CSS class to the element
      }
    }

    $(".case-studies-slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 4000,
      centerMode: true,
      centerPadding: "10%",
      infinite: false,
    });

    $(".testimonial-carousel-js").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 4000,
      centerMode: true,
      centerPadding: "300px",
      infinite: true,
      prevArrow: $(".testimonial-carousel-prev", this),
      nextArrow: $(".testimonial-carousel-next", this),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            centerMode: true,
            centerPadding: "120px",
          },
        },
        {
          breakpoint: 1200,
          settings: {
            centerMode: true,
            centerPadding: "80px",
          },
        },
        {
          breakpoint: 1440,
          settings: {
            centerMode: true,
            centerPadding: "110px",
          },
        },
        {
          breakpoint: 1920,
          settings: {
            centerMode: true,
            centerPadding: "190px",
          },
        },
        // {
        //   breakpoint: 1919,
        //   settings: {
        //     centerMode: true,
        //     centerPadding: "300px",
        //   },
        // },
      ],
    });

    //logo slider
    $(".autoslide").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      // responsive: [
      //   {
      //     breakpoint: 768,
      //     settings: {
      //       slidesToShow: 1,
      //       centerMode: true,
      //       centerPadding: "100px",
      //     },
      //   },
      // ],
    });

    //Post
    $(".slider-featured-post").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            dots: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            dots: true,
            centerMode: true,
            centerPadding: "65px",
          },
        },
      ],
    });

    // Content carousel
    $(".content-carousel-js").each(function () {
      $(this).slick({
        dots: false,
        arrows: true,
        autoplay: false,
        speed: 900,
        autoplaySpeed: 4000,
        adaptiveHeight: true,
        prevArrow: $(".content-carousel-prev", this),
        nextArrow: $(".content-carousel-next", this),
      });
    });

    // Business carousel
    $(".team-carousel-js").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 2000,
      prevArrow: $(".team-carousel-prev", this),
      nextArrow: $(".team-carousel-next", this),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
    //Hide carousel nav if less than 5 team members (as there are 4 items to scroll)
    if ($(".team-carousel-js .slick-slide").length < 5) {
      $(".team-carousel-nav").addClass("d-none");
    }
  });
})(jQuery);
